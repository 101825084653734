import { ChangeEvent, ReactElement, ReactNode } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Form, Upload, UploadProps } from 'antd'
import classNames from 'classnames'

import FolderIcon from '@/components/icons/FolderIcon'
import {
  TFormFile,
  getFilesFromEvent,
  validateFileList
} from '@/utils/formFileList'
import removeFile from '@/utils/removeFile'

import './FormUploadInput.scss'

type TFormUploadInputProps = UploadProps & {
  name: string
  fileList?: TFormFile[]
  required?: boolean
  onChange?: (event: any) => void
  className?: string
  children?: ReactNode
}

const FormUploadInput = ({
  name,
  fileList = [],
  maxCount = 3,
  required = true,
  onChange = () => {},
  className = '',
  children = <></>,
  ...props
}: TFormUploadInputProps): ReactElement => {
  return (
    <div className={`upload-input__upload-wrapper ${className}`}>
      <Form.Item
        name={name}
        valuePropName='fileList'
        getValueFromEvent={getFilesFromEvent}
        rules={[
          {
            validator: validateFileList({
              fileList,
              required
            })
          }
        ]}
      >
        <Upload
          type='drag'
          beforeUpload={(): boolean => false}
          onChange={onChange}
          iconRender={(): null => null}
          multiple
          maxCount={maxCount}
          rootClassName={classNames('upload-input__wrapper', {
            disabled: fileList.length >= 10
          })}
          showUploadList={{
            showRemoveIcon: true,
            removeIcon: (file: TFormFile): ReactNode => (
              <CloseOutlined
                style={{
                  fontSize: 18,
                  cursor: 'pointer',
                  color: 'var(--tertiary-gray)'
                }}
                onClick={() => {
                  removeFile(file.id)
                }}
              />
            )
          }}
          {...props}
        >
          <div className='upload-input__upload-trigger'>
            {children}
            <FolderIcon />
            <h3 className='upload-input__label upload-input__upload-trigger__label'>
              Drag and drop files <br />
              or <br />
              <u>browse</u>
            </h3>
          </div>
        </Upload>
      </Form.Item>
    </div>
  )
}

export default FormUploadInput
