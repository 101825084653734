import React from 'react'

import { Navigate } from 'react-router-dom'

import LoginForm from '@/components/login/LoginForm'
import { useTypedSelector } from '@/hooks/redux'
import loanBudAdminLogo from '@/images/loan-bud-admin-logo.svg'
import EAppRoute from '@/types/appRoutes'

import './Login.scss'

const Login = () => {
  const isLoggedIn = useTypedSelector((state) => state.auth.isLoggedIn)

  if (isLoggedIn) {
    return <Navigate to={EAppRoute.Dashboard} />
  }

  return (
    <section className={'login-page'}>
      <div className={'login-page__logo'}>
        <img src={loanBudAdminLogo} alt={'LoanBud logo'} />
      </div>
      <div className={'login-page__form-wrapper'}>
        <LoginForm />
      </div>
    </section>
  )
}

export default Login
