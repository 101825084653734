import React from 'react'

interface IEditIconProps {
  isHovered?: boolean
}

const EditIcon: React.FC<IEditIconProps> = ({ isHovered = false }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        transition: 'stroke 0.3s ease',
        stroke: isHovered
          ? 'var(--brand-primary-hover)'
          : 'var(--brand-primary)'
      }}
    >
      <path
        d='M9.2 2H7.6C3.6 2 2 3.6 2 7.6V12.4C2 16.4 3.6 18 7.6 18H12.4C16.4 18 18 16.4 18 12.4V10.8'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1831 2.98599L7.07208 9.09785C6.83942 9.33054 6.60677 9.78815 6.56024 10.1217L6.22677 12.4563C6.10269 13.3017 6.69983 13.8912 7.54514 13.7748L9.87941 13.4413C10.2051 13.3948 10.6627 13.1621 10.9031 12.9294L17.0141 6.81754C18.0688 5.7627 18.5651 4.53722 17.0141 2.98599C15.4631 1.43475 14.2378 1.93115 13.1831 2.98599Z'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.1055 3.68433C12.6765 5.7214 14.2704 7.31527 16.316 7.89485'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default EditIcon
