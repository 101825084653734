import { createSlice, current } from '@reduxjs/toolkit'

interface IFormStateInitialState {
  applicationId: string
  currentStep: number
  hiddenSteps: number[]
  isNavigatedFromReview: boolean
  stepsCount: number
  onBackToReviewClick?: (step?: number) => Promise<void>
  direction: 'forward' | 'backward'
}

const initialState: IFormStateInitialState = {
  applicationId: '',
  stepsCount: 0,
  currentStep: 0,
  hiddenSteps: [],
  isNavigatedFromReview: false,
  direction: 'forward'
}

const formStateSlice = createSlice({
  name: 'formState',
  initialState,
  reducers: {
    setDirection: (
      state,
      action: { payload: IFormStateInitialState['direction'] }
    ) => {
      state.direction = action.payload
    },
    setOnBackToReviewClick: (
      state,
      action: { payload: (step?: number) => Promise<void> }
    ) => {
      state.onBackToReviewClick = action.payload
    },
    setApplicationId: (state, action: { payload: string }) => {
      state.applicationId = action.payload
    },
    setStepsCount: (state, action: { payload: number }) => {
      state.stepsCount = action.payload
    },
    changeStep: (
      state,
      action: {
        payload:
          | {
              currentStep?: number
              direction?: IFormStateInitialState['direction']
            }
          | undefined
      }
    ) => {
      const isForwardDirection = action.payload?.direction === 'forward'
      const isBackwardDirection = action.payload?.direction === 'backward'
      const currentStep = action.payload?.currentStep ?? state.currentStep

      if (action.payload?.currentStep || !action.payload?.direction) {
        state.currentStep = currentStep
        return
      }

      let nextStep = currentStep + 1
      let prevStep = currentStep - 1

      if (isForwardDirection && nextStep < state.stepsCount) {
        while (current(state.hiddenSteps).includes(nextStep)) {
          nextStep++
        }

        state.currentStep = nextStep
      } else if (isBackwardDirection && prevStep >= 0) {
        while (current(state.hiddenSteps).includes(prevStep)) {
          prevStep--
        }
        state.currentStep = prevStep
      }
    },
    addHiddenStep: (state, action: { payload: number }) => {
      state.hiddenSteps = [...state.hiddenSteps, action.payload]
    },
    removeHiddenStep: (state, action: { payload: number }) => {
      state.hiddenSteps = state.hiddenSteps.filter(
        (step) => step !== action.payload
      )
    },
    setIsNavigatedFromReview: (state, action: { payload: boolean }) => {
      state.isNavigatedFromReview = action.payload
    },
    toggleIsNavigatedFromReview: (state) => {
      state.isNavigatedFromReview = !state.isNavigatedFromReview
    }
  }
})

export const {
  addHiddenStep,
  removeHiddenStep,
  toggleIsNavigatedFromReview,
  changeStep,
  setApplicationId,
  setStepsCount,
  setOnBackToReviewClick,
  setDirection
} = formStateSlice.actions
export default formStateSlice.reducer
