import React from 'react'

import { Navigate } from 'react-router-dom'

import { useTypedSelector } from '@/hooks/redux'
import EAppRoute from '@/types/appRoutes'

interface IAuthProtectedProps {
  children: React.ReactNode
}

const AuthProtected: React.FC<IAuthProtectedProps> = ({ children }) => {
  const isLoggedIn = useTypedSelector((state) => state.auth.isLoggedIn)

  return isLoggedIn ? <>{children}</> : <Navigate to={EAppRoute.Login} />
}

export default AuthProtected
