import React from 'react'

import { Provider } from 'react-redux'

import App from '@/App'
import { store } from '@/redux/app/store'

const WithAppProviders: React.FC = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

export { WithAppProviders }
