import React, { useEffect, useState } from 'react'

import {
  CloseOutlined,
  DeleteOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Tooltip,
  message
} from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import { deleteFile, getFile, uploadSingleFile } from '@/api/file.service'
import {
  deletePartner,
  getBranches,
  getPartnerById,
  updatePartnerDataById
} from '@/api/partners.service'
import AdminHeader from '@/components/admin-header/AdminHeader'
import FormUploadInput from '@/components/form/form-upload-input/FormUploadInput'
import CopyIcon from '@/components/icons/CopyIcon'
import EditIcon from '@/components/icons/EditIcon'
import backArrowIcon from '@/images/icons/back-arrow.svg'
import hiddenIcon from '@/images/icons/hidden.svg'
import placeholderImage from '@/images/icons/placeholder-image.svg'
import visibleIcon from '@/images/icons/visible.svg'
import EAppRoute from '@/types/appRoutes'
import { Branch, PartnerDto } from '@/types/partners'

import './Partner.scss'

const Partner = () => {
  const navigate = useNavigate()
  const [partnerData, setPartnerData] = useState<PartnerDto>({ name: '' })
  const [uneditedPartnerData, setUneditedPartnerData] = useState<PartnerDto>({
    name: ''
  })
  const [uneditedPreviewImage, setUneditedPreviewImage] =
    useState<string>(placeholderImage)
  const [branchList, setBranchList] = useState<Branch[]>([])
  const params = useParams()

  const [isEditIconHovered, setIsEditIconHovered] = useState<boolean>(false)
  const [isCopyIconHovered1, setIsCopyIconHovered1] = useState<boolean>(false)
  const [isCopyIconHovered2, setIsCopyIconHovered2] = useState<boolean>(false)
  const [isUploadHovered, setIsUploadHovered] = useState<boolean>(false)

  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false)
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false)
  const [isDeleteLogoLoading, setIsDeleteLogoLoading] = useState<boolean>(false)
  const [isDeletePartnerLoading, setIsDeletePartnerLoading] =
    useState<boolean>(false)

  const [form] = useForm()
  const logoFile = useWatch('partner-logo', form)
  const [previewImage, setPreviewImage] = useState(placeholderImage)

  const handleFileChange = (info: any) => {
    const file = info.file
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setPreviewImage(e.target?.result as string)
      }
      reader.readAsDataURL(file)
    } else {
    }
  }

  useEffect(() => {
    setIsInitialLoading(true)
    getPartnerById(params['partnerId'] as string)
      .then((response) => {
        if (response.logo?.id) {
          getFile(response.logo.id as string)
            .then((redirectResponse) => {
              axios
                .get(redirectResponse.data.url, { responseType: 'arraybuffer' })
                .then((awsResponse) => {
                  const blob = new Blob([awsResponse.data], {
                    type: 'image/png'
                  })

                  const reader = new FileReader()
                  reader.onloadend = () => {
                    setPreviewImage(reader.result as string)
                  }
                  reader.readAsDataURL(blob)
                  setIsInitialLoading(false)
                  setPartnerData(response)
                })
                .catch((error) => {
                  message.error(
                    error.response?.data?.message ||
                      'Could not fetch partner logo!'
                  )
                  setIsInitialLoading(false)
                  setPartnerData(response)
                })
            })
            .catch((error) => {
              message.error(
                error.response?.data?.message || 'Could not fetch partner logo!'
              )
              setIsInitialLoading(false)
              setPartnerData(response)
            })
        } else {
          setPartnerData(response)
          setIsInitialLoading(false)
        }
      })
      .catch((error) => {
        message.error(
          error.response?.data?.message || 'Could not fetch partner!'
        )
        setIsInitialLoading(false)
      })
    getBranches()
      .then((response) => {
        setBranchList(response)
      })
      .catch((error) => {
        message.error(
          error.response?.data?.message || 'Could not fetch branches!'
        )
      })
  }, [])

  const updatePartnerDataField = (name: string, value: string): void => {
    if (name === 'branch') {
      setPartnerData((prev) => {
        return {
          ...prev,
          branch: branchList.find((branch) => branch.name === value)
        }
      })
    } else {
      setPartnerData((prev) => {
        return { ...prev, [name]: value }
      })
    }
  }

  const [oldImageData, setOldImageData] = useState(partnerData.logo)

  // Edit mode
  const [isEditing, setIsEditing] = useState(false)
  const saveChanges = () => {
    setIsSaveLoading(true)
    if (logoFile?.length > 0) {
      setOldImageData(partnerData.logo)
      uploadSingleFile(logoFile)
        .then((fileData) => {
          updatePartnerDataById(partnerData.id as string, {
            ...partnerData,
            logo: fileData
          })
            .then((newPartnerData) => {
              setPartnerData(newPartnerData)
              message.success('Partner data updated successfully!')
              setIsSaveLoading(false)
              setIsEditing(false)

              if (oldImageData) {
                deleteFile(oldImageData.id as string)
              }
            })
            .catch((error) => {
              message.error(
                error.response?.data?.message || 'Something went wrong!'
              )
              setIsSaveLoading(false)
            })
        })
        .catch((error) => {
          message.error(
            error.response?.data?.message || 'Something went wrong!'
          )
          setIsSaveLoading(false)
        })
    } else {
      updatePartnerDataById(partnerData.id as string, partnerData)
        .then(() => {
          message.success('Partner data updated successfully!')
          setIsSaveLoading(false)
          setIsEditing(false)
        })
        .catch((error) => {
          message.error(
            error.response?.data?.message || 'Something went wrong!'
          )
          setIsSaveLoading(false)
        })
    }
  }
  const startEditing = () => {
    setIsEditing(true)
    setUneditedPartnerData(partnerData)
    setUneditedPreviewImage(previewImage)
  }
  const cancelEditing = () => {
    setIsEditing(false)
    setPartnerData(uneditedPartnerData)
    setPreviewImage(uneditedPreviewImage)
  }
  const deleteExistingLogo = () => {
    setIsModalVisible(true)
    setModalText(`Are you sure you want to delete existing logo?`)
    setIsDeletingLogo(true)
  }
  const confirmDeleteLogo = () => {
    if (partnerData.logo && partnerData.logo.id) {
      deleteFile(partnerData.logo.id)
        .then(() => {
          updatePartnerDataById(partnerData.id as string, {
            ...partnerData,
            logo: undefined
          })
            .then((updatedPartnerData) => {
              setPartnerData(updatedPartnerData)
              setIsModalVisible(false)
              message.success('Logo deleted successfully!')
              setPreviewImage(placeholderImage)
              setIsDeleteLogoLoading(false)
            })
            .catch((error) => {
              message.error(
                error.response?.data?.message || 'Something went wrong!'
              )
              setIsDeleteLogoLoading(false)
            })
        })
        .catch((error) => {
          message.error(
            error.response?.data?.message || 'Something went wrong!'
          )
          setIsDeleteLogoLoading(false)
        })
    }
  }

  // Modal
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalText, setModalText] = useState<string>('')
  const [isDeletingLogo, setIsDeletingLogo] = useState<boolean>(false)
  const openModal = () => {
    setIsModalVisible(true)
    setModalText(`Are you sure you want to delete ${partnerData?.name}?`)
    setIsDeletingLogo(false)
  }
  const cancelDelete = () => {
    setIsModalVisible(false)
  }

  const confirmDelete = () => {
    setIsModalVisible(false)
    deletePartner(partnerData.id as string)
      .then(() => {
        setIsDeletePartnerLoading(false)
        message.success('Partner deleted!')
        navigate(EAppRoute.Dashboard)
      })
      .catch((error) => {
        message.error(error.response?.data?.message || 'Something went wrong!')
        setIsDeletePartnerLoading(false)
      })
  }

  const handleGoBack = () => {
    navigate(EAppRoute.Dashboard)
  }

  // API key
  const [isKeyVisible, setIsKeyVisible] = useState<boolean>(false)
  const toggleVisibility = () => {
    setIsKeyVisible((prev) => !prev)
  }

  const generatedIframe = `<iframe width="560" height="315" src="https://dev.loanbud.io/#/?accessToken=${partnerData.apiKey}"></iframe>`

  const handleCopy = (value: string, name: string) => {
    navigator.clipboard.writeText(value)
    message.success(`${name} copied!`)
  }

  return (
    <section className={'partner-wrapper'}>
      {isInitialLoading && (
        <div className={'partner__spinner-container'}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      )}
      <div className={'partner'}>
        <div className={'partner__header'}>
          <Button
            type={'link'}
            onClick={handleGoBack}
            disabled={false}
            className={'add-partner__back'}
          >
            <img src={backArrowIcon} alt={'Back arrow icon'} />
            Back
          </Button>
          <div className={'partner__edit-buttons'}>
            {isEditing ? (
              <>
                <Button onClick={cancelEditing} disabled={isSaveLoading}>
                  Cancel
                </Button>
                <Button
                  type='primary'
                  onClick={saveChanges}
                  loading={isSaveLoading}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                onClick={startEditing}
                icon={<EditIcon isHovered={isEditIconHovered} />}
                className={'partner__edit-button'}
                onMouseOver={() => setIsEditIconHovered(true)}
                onMouseLeave={() => setIsEditIconHovered(false)}
              >
                Edit
              </Button>
            )}
          </div>
        </div>
        <Card className={'partner__card-form'}>
          <AdminHeader />
          <div className={'partner__form-wrapper'}>
            <div className={'partner__logo-wrapper'}>
              {!isEditing && (
                <div className={'partner__logo'}>
                  <img
                    src={previewImage}
                    alt='Logo'
                    style={{ maxWidth: 100, maxHeight: 100, borderRadius: 8 }}
                  />
                </div>
              )}
              {isEditing && (
                <Form form={form} style={{ position: 'relative' }}>
                  {isUploadHovered && partnerData?.logo?.id && (
                    <button
                      onClick={deleteExistingLogo}
                      className={'partner__image-delete-button'}
                      onMouseOver={() => setIsUploadHovered(true)}
                    >
                      <DeleteOutlined /> Delete
                    </button>
                  )}
                  <FormUploadInput
                    name={'partner-logo'}
                    fileList={logoFile}
                    maxCount={1}
                    required={false}
                    onChange={handleFileChange}
                    className={'partner__upload'}
                    children={
                      <div
                        className={`partner__logo ${
                          isUploadHovered ? 'hovered' : ''
                        }`}
                        onMouseOver={() => setIsUploadHovered(true)}
                        onMouseLeave={() => setIsUploadHovered(false)}
                      >
                        <img
                          src={previewImage}
                          alt='Logo'
                          style={{
                            maxWidth: 100,
                            maxHeight: 100,
                            borderRadius: 8
                          }}
                        />
                      </div>
                    }
                  />
                </Form>
              )}
            </div>
            <div>
              <Form className={'partner__form'}>
                <div className={'partner__form-item'}>
                  <p>Partner Name</p>
                  <Input
                    value={partnerData?.name as string}
                    variant={isEditing ? 'outlined' : 'borderless'}
                    className={`partner__input ${isEditing ? '' : 'gray'}`}
                    readOnly={!isEditing}
                    onChange={(e) =>
                      updatePartnerDataField('name', e.target.value)
                    }
                    suffix={
                      isEditing && (
                        <CloseOutlined
                          onClick={() => updatePartnerDataField('name', '')}
                          style={{ cursor: 'pointer' }}
                        />
                      )
                    }
                  />
                </div>
                <div className={'partner__form-item'}>
                  <p>URL</p>
                  <Input
                    value={partnerData?.url}
                    variant={isEditing ? 'outlined' : 'borderless'}
                    className={`partner__input blue-font ${
                      isEditing ? '' : 'gray'
                    }`}
                    readOnly={!isEditing}
                    onChange={(e) =>
                      updatePartnerDataField('url', e.target.value)
                    }
                    suffix={
                      isEditing && (
                        <CloseOutlined
                          onClick={() => updatePartnerDataField('url', '')}
                          style={{ cursor: 'pointer' }}
                        />
                      )
                    }
                  />
                </div>
                <div className={'partner__form-item'}>
                  <p>Primary Color</p>
                  <Input
                    value={partnerData?.primaryColor}
                    variant={isEditing ? 'outlined' : 'borderless'}
                    className={`partner__input ${isEditing ? '' : 'gray'}`}
                    readOnly={!isEditing}
                    onChange={(e) =>
                      updatePartnerDataField('primaryColor', e.target.value)
                    }
                    prefix={
                      <div
                        className={'partner__color'}
                        style={{ background: partnerData?.primaryColor }}
                      ></div>
                    }
                    suffix={
                      isEditing && (
                        <CloseOutlined
                          onClick={() =>
                            updatePartnerDataField('primaryColor', '')
                          }
                          style={{ cursor: 'pointer' }}
                        />
                      )
                    }
                  />
                </div>
                <div className={'partner__form-item'}>
                  <p>API Key</p>
                  <div className={'partner__textarea-container'}>
                    <div className={'partner__api-key-container'}>
                      {isKeyVisible ? (
                        <Input.TextArea
                          rows={2}
                          value={partnerData.apiKey}
                          readOnly
                          variant={'borderless'}
                        />
                      ) : (
                        <Input
                          value={partnerData?.apiKey}
                          readOnly
                          type={isKeyVisible ? 'text' : 'password'}
                          variant={'borderless'}
                          style={{
                            cursor: 'default',
                            flexGrow: '1'
                          }}
                        />
                      )}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8
                        }}
                      >
                        <span
                          style={{
                            height: 16,
                            borderLeft: '1px solid #d9d9d9'
                          }}
                        ></span>
                        <Tooltip title={isKeyVisible ? 'Hide' : 'Show'}>
                          <Button
                            icon={
                              isKeyVisible ? (
                                <img src={visibleIcon} alt={'Visible icon'} />
                              ) : (
                                <img src={hiddenIcon} alt={'Hidden icon'} />
                              )
                            }
                            size='small'
                            onClick={toggleVisibility}
                            type={'text'}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <Button
                      className={'partner__copy-button'}
                      onClick={() => handleCopy(partnerData?.apiKey, 'Api key')}
                      onMouseOver={() => setIsCopyIconHovered1(true)}
                      onMouseLeave={() => setIsCopyIconHovered1(false)}
                      icon={
                        <CopyIcon
                          isGreenByDefault={true}
                          isHovered={isCopyIconHovered1}
                        />
                      }
                    >
                      Copy
                    </Button>
                  </div>
                </div>
                <div className={'partner__form-item'}>
                  <p>iFrame</p>
                  <div className={'partner__textarea-container'}>
                    <Input.TextArea
                      rows={5}
                      value={generatedIframe}
                      readOnly={!isEditing}
                      variant={'borderless'}
                    />
                    <Button
                      className={'partner__copy-button'}
                      onClick={() => handleCopy(generatedIframe, 'iFrame')}
                      onMouseOver={() => setIsCopyIconHovered2(true)}
                      onMouseLeave={() => setIsCopyIconHovered2(false)}
                      icon={
                        <CopyIcon
                          isGreenByDefault={true}
                          isHovered={isCopyIconHovered2}
                        />
                      }
                    >
                      Copy
                    </Button>
                  </div>
                </div>
                <div className={'partner__form-item'}>
                  <p>Branch</p>
                  <div className={'partner__select'}>
                    {isEditing ? (
                      <Select
                        placeholder='Select Branch'
                        showSearch
                        value={partnerData?.branch?.name}
                        onChange={(value) =>
                          updatePartnerDataField('branch', value)
                        }
                      >
                        {branchList.map((branch) => (
                          <Select.Option key={branch.id} value={branch.name}>
                            {branch.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        value={partnerData?.branch?.name}
                        variant={isEditing ? 'outlined' : 'borderless'}
                        className={`partner__input ${isEditing ? '' : 'gray'}`}
                        readOnly={!isEditing}
                      />
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Card>
        <Card className={'partner__card-terms'}>
          <div className={'partner__terms-wrapper'}>
            <div className={'partner__terms-item'}>
              <p>Terms of Service</p>
              <Input.TextArea
                rows={7}
                value={partnerData?.termsOfUse}
                onChange={(e) =>
                  updatePartnerDataField('termsOfUse', e.target.value)
                }
                readOnly={!isEditing}
                placeholder={'Enter Terms of Service'}
              />
            </div>
            <div className={'partner__terms-item'}>
              <p>Privacy Policy</p>
              <Input.TextArea
                rows={7}
                value={partnerData?.privacyPolicy}
                onChange={(e) =>
                  updatePartnerDataField('privacyPolicy', e.target.value)
                }
                readOnly={!isEditing}
                placeholder={'Enter Privacy Policy'}
              />
            </div>
          </div>

          <Modal
            open={isModalVisible}
            styles={{ content: { padding: '65px 56px' } }}
            closable={false}
            centered={true}
            footer={
              <div>
                <Button
                  type={'primary'}
                  onClick={() => {
                    if (isDeletingLogo) {
                      confirmDeleteLogo()
                      setIsDeleteLogoLoading(true)
                    } else {
                      confirmDelete()
                      setIsDeletePartnerLoading(true)
                    }
                  }}
                  disabled={false}
                  loading={isDeletePartnerLoading || isDeleteLogoLoading}
                  className={'dashboard__modal-button primary'}
                >
                  Delete
                </Button>
                <Button
                  type={'default'}
                  onClick={cancelDelete}
                  disabled={isDeletePartnerLoading || isDeleteLogoLoading}
                  className={'dashboard__modal-button secondary'}
                >
                  Cancel
                </Button>
              </div>
            }
          >
            <p className={'dashboard__modal-title'}>{modalText}</p>
          </Modal>
        </Card>

        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={openModal}
          className={'partner__delete-button'}
        >
          Delete Partner
        </Button>
      </div>
    </section>
  )
}

export default Partner
