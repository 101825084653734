import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Spin,
  Table,
  Tooltip,
  message
} from 'antd'
import { debounce } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'

import { getFile } from '@/api/file.service'
import { getPartners, getPartnersBySearchQuery } from '@/api/partners.service'
import AdminHeader from '@/components/admin-header/AdminHeader'
import copyIcon from '@/images/icons/copy.svg'
// import deleteIcon from '@/images/icons/delete.svg'
// import editIcon from '@/images/icons/edit.svg'
import hiddenIcon from '@/images/icons/hidden.svg'
// import iframeIcon from '@/images/icons/iframe.svg'
import searchIcon from '@/images/icons/search.svg'
// import threeDotsIcon from '@/images/icons/three-dots.svg'
import visibleIcon from '@/images/icons/visible.svg'
import EAppRoute from '@/types/appRoutes'
import { PartnerDto } from '@/types/partners'

import './Dashboard.scss'

interface IVisibilityState {
  key?: string
  isVisible: boolean
}

const Dashboard = () => {
  // const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  // const [selectedPartner, setSelectedPartner] = useState('')
  const navigate = useNavigate()
  const [partnersData, setPartnersData] = useState<PartnerDto[]>([])
  const [keysVisibilityState, setKeysVisibilityState] = useState<
    IVisibilityState[]
  >([])
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true)

  // const handleDelete = (record: any) => {
  //   setSelectedPartner(record.name)
  //   setIsModalVisible(true)
  // }

  // const handleCancel = () => {
  //   setIsModalVisible(false)
  // }

  // const handleConfirmDelete = () => {
  //   console.log(`Deleted: ${selectedPartner}`)
  //   setIsModalVisible(false)
  // }

  // const dropdownMenu = (record: any) => [
  //   {
  //     key: '1',
  //     label: (
  //       <button
  //         onClick={() => navigate(`${EAppRoute.Partner}/${record.key}`)}
  //         className={'dashboard__menu-item'}
  //       >
  //         <img src={editIcon} alt={'Edit icon'} />
  //         Edit
  //       </button>
  //     )
  //   },
  //   {
  //     key: '2',
  //     label: (
  //       <button
  //         onClick={() => console.log(`Generate iframe for ${record.name}`)}
  //         className={'dashboard__menu-item'}
  //       >
  //         <img src={iframeIcon} alt={'Generate iframe icon'} />
  //         Generate Iframe
  //       </button>
  //     )
  //   },
  //   {
  //     key: '3',
  //     label: (
  //       <button
  //         onClick={() => handleDelete(record)}
  //         className={'dashboard__menu-item red'}
  //       >
  //         <img src={deleteIcon} alt={'Delete icon'} />
  //         Delete
  //       </button>
  //     )
  //   }
  // ]

  const renderApiKey = (apiKey: string, record: any) => {
    const toggleVisibility = () => {
      setKeysVisibilityState((prevState) => {
        return prevState.map((item) => {
          if (item.key === record.key) {
            return { key: item.key, isVisible: !item.isVisible }
          } else {
            return item
          }
        })
      })
    }

    const handleCopy = () => {
      navigator.clipboard.writeText(apiKey)
      message.success('Api key copied!')
    }

    const isItemVisible = keysVisibilityState.find(
      (item) => item.key === record.key
    )?.isVisible

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {isItemVisible ? (
          <Input.TextArea
            rows={2}
            value={apiKey}
            readOnly
            variant={'borderless'}
            style={{ resize: 'none' }}
          />
        ) : (
          <Input
            value={apiKey}
            readOnly
            type={isItemVisible ? 'text' : 'password'}
            variant={'borderless'}
            style={{ width: '100%', cursor: 'default', flexGrow: '1' }}
          />
        )}

        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <span style={{ height: 16, borderLeft: '1px solid #d9d9d9' }}></span>
          <Tooltip title={isItemVisible ? 'Hide' : 'Show'}>
            <Button
              icon={
                isItemVisible ? (
                  <img src={visibleIcon} alt={'Visible icon'} />
                ) : (
                  <img src={hiddenIcon} alt={'Hidden icon'} />
                )
              }
              size='small'
              onClick={toggleVisibility}
              type={'text'}
            />
          </Tooltip>
          <span style={{ height: 16, borderLeft: '1px solid #d9d9d9' }}></span>
          <Tooltip title='Copy'>
            <Button
              icon={<img src={copyIcon} alt={'copy icon'} />}
              size='small'
              onClick={handleCopy}
              type={'text'}
            />
          </Tooltip>
        </div>
      </div>
    )
  }

  const columns = [
    {
      title: 'Partner Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => {
        return a.name.props.children.localeCompare(b.name.props.children)
      },
      sortDirections: ['ascend', 'descend'],
      width: 'calc(100% / 3)'
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (url: string) => (
        <a href={url} target='_blank' rel='noopener noreferrer'>
          {url}
        </a>
      ),
      width: 'calc(100% / 3)'
    },
    {
      title: 'API Key',
      dataIndex: 'apiKey',
      key: 'apiKey',
      render: (apiKey: string, record: any) => renderApiKey(apiKey, record),
      width: 'calc(100% / 3)'
    }
    // {
    //   title: '',
    //   key: 'actions',
    //   render: (_: any, record: any) => (
    //     <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    //       <Dropdown
    //         menu={{ items: [...dropdownMenu(record)] }}
    //         trigger={['click']}
    //       >
    //         <img
    //           src={threeDotsIcon}
    //           alt={'three dots icon'}
    //           style={{ cursor: 'pointer' }}
    //         />
    //       </Dropdown>
    //     </div>
    //   )
    // }
  ]

  const handleAddPartnerClick = () => {
    navigate(EAppRoute.AddPartner)
  }

  useEffect(() => {
    setIsInitialLoading(true)
    getPartners()
      .then((partners) => {
        setPartnersData(
          partners.map((partner) => ({
            ...partner,
            key: partner.id,
            name: (
              <Link
                to={`${EAppRoute.Partner}/${partner.id}`}
                className={'dashboard__partner-link'}
              >
                {partner.name}
              </Link>
            )
          }))
        )

        const visibilityStates: IVisibilityState[] = partners.map(
          (item: PartnerDto) => {
            return { key: item.id, isVisible: false }
          }
        )

        setKeysVisibilityState(visibilityStates)
        setIsInitialLoading(false)
      })
      .catch((error) => {
        message.error(error.response?.data?.message || 'Something went wrong!')
        setIsInitialLoading(false)
      })
  }, [])

  const handleSearch = useRef(
    debounce((query: string) => {
      getPartnersBySearchQuery(query)
        .then((partners) => {
          setPartnersData(
            partners.map((partner) => ({
              ...partner,
              key: partner.id,
              name: (
                <Link
                  to={`${EAppRoute.Partner}/${partner.id}`}
                  className={'dashboard__partner-link'}
                >
                  {partner.name}
                </Link>
              )
            }))
          )
          setIsInitialLoading(false)
        })
        .catch((error) => {
          message.error(
            error.response?.data?.message || 'Something went wrong!'
          )
          setIsInitialLoading(false)
        })
    }, 50)
  ).current

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsInitialLoading(true)
    handleSearch(event.target.value)
  }

  return (
    <div className={'dashboard'}>
      {isInitialLoading && (
        <div className={'partner__spinner-container'}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      )}

      <AdminHeader />

      <div className={'dashboard__table-header'}>
        <div className={'dashboard__search-container'}>
          <p>
            {partnersData.length} {partnersData.length === 1 ? 'Row' : 'Rows'}
          </p>
          <div className={'dashboard__search-divider'}></div>
          <Form.Item className={'dashboard__input'}>
            <Input
              placeholder='Search'
              prefix={<img src={searchIcon} alt={'search icon'} />}
              onChange={onSearchChange}
            />
          </Form.Item>
        </div>
        <Button
          type='primary'
          className={'dashboard__add-button'}
          onClick={handleAddPartnerClick}
        >
          Add Partner
        </Button>
      </div>

      <Table
        dataSource={partnersData}
        columns={columns}
        pagination={{ pageSize: 9999, position: ['none'] }}
      />

      {/*<Modal*/}
      {/*  open={isModalVisible}*/}
      {/*  styles={{ content: { padding: '65px 56px' } }}*/}
      {/*  closable={false}*/}
      {/*  centered={true}*/}
      {/*  footer={*/}
      {/*    <div>*/}
      {/*      <Button*/}
      {/*        type={'primary'}*/}
      {/*        onClick={handleConfirmDelete}*/}
      {/*        disabled={false}*/}
      {/*        className={'dashboard__modal-button primary'}*/}
      {/*      >*/}
      {/*        Delete*/}
      {/*      </Button>*/}
      {/*      <Button*/}
      {/*        type={'default'}*/}
      {/*        onClick={handleCancel}*/}
      {/*        disabled={false}*/}
      {/*        className={'dashboard__modal-button secondary'}*/}
      {/*      >*/}
      {/*        Cancel*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <p className={'dashboard__modal-title'}>*/}
      {/*    Are you sure you want to delete {selectedPartner}?*/}
      {/*  </p>*/}
      {/*</Modal>*/}
    </div>
  )
}

export default Dashboard
