import { useQueryClient } from '@tanstack/react-query'

import { partnersQueries } from '@/api/partners.service'
import { PartnerDto } from '@/generated-types'

const defaultColorsMap = {
  'primary-gray': '#dbdde0',
  'brand-primary': '#13a55b',
  'brand-primary-hover': '#16c46e',
  'brand-secondary': '#eef9f3',
  'main-text': '#404752',
  'tertiary-text': '#5f6774',
  gray: '#f1f1f1',
  'gray-2': '#fafafa',
  'tertiary-gray': '#a4a4a4'
}

const useGetDefaultColorsMap = (): typeof defaultColorsMap => {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData<Required<PartnerDto>>(
    partnersQueries.partner.me.queryKey
  )

  const brandPrimaryColor =
    data?.primaryColor ?? defaultColorsMap['brand-primary']

  const newColors = {
    'brand-primary': brandPrimaryColor,
    'brand-primary-hover': brightenColor(brandPrimaryColor, 30)
    // NOTE: we don't have secondary color form BE for now (MG)
    // 'brand-secondary':
    //   data?.secondaryColor ?? defaultColorsMap['brand-secondary']
  }

  const colorsMap = { ...defaultColorsMap, ...newColors }

  for (const [key, value] of Object.entries(colorsMap) as [
    key: keyof typeof defaultColorsMap,
    value: string
  ][]) {
    document.documentElement.style.setProperty(`--${key}`, value)
    document.documentElement.style.setProperty(`--${key}-rgb`, hexToRgb(value))
  }

  return colorsMap
}

const hexToRgb = (hex: string) => {
  const [r, g, b] = (hex.match(/\w\w/g) || []).map((x) => parseInt(x, 16))
  return `${r}, ${g}, ${b}`
}

const brightenColor = (hex: string, percent: number): string => {
  hex = hex.replace(/^#/, '')

  if (hex.length !== 6) {
    throw new Error('Invalid hex color format. Use a 6-character hex value.')
  }

  let r: number = parseInt(hex.substring(0, 2), 16)
  let g: number = parseInt(hex.substring(2, 4), 16)
  let b: number = parseInt(hex.substring(4, 6), 16)

  r = Math.min(255, Math.floor(r * (1 + percent / 100)))
  g = Math.min(255, Math.floor(g * (1 + percent / 100)))
  b = Math.min(255, Math.floor(b * (1 + percent / 100)))

  const brightenedHex = `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`

  return brightenedHex
}

export default useGetDefaultColorsMap
