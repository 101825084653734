import React from 'react'

import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

import AddPartnerForm from '@/components/add-partner/AddPartnerForm'
import AdminHeader from '@/components/admin-header/AdminHeader'
import backArrowIcon from '@/images/icons/back-arrow.svg'
import EAppRoute from '@/types/appRoutes'

import './AddPartner.scss'

const AddPartner = () => {
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(EAppRoute.Dashboard)
  }

  return (
    <section className={'add-partner'}>
      <AdminHeader />
      <Button
        type={'link'}
        onClick={handleGoBack}
        disabled={false}
        className={'add-partner__back'}
      >
        <img src={backArrowIcon} alt={'Back arrow icon'} />
        Back
      </Button>
      <AddPartnerForm />
    </section>
  )
}

export default AddPartner
