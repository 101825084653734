import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'antd/dist/reset.css'

import '@/styles/custom-styles.scss'
import '@/styles/reset.scss'

import AppRouter from './router'

const queryClient = new QueryClient()

function App() {
  return (
    <div className='app'>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
    </div>
  )
}

export default App
