import React, { useState } from 'react'

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'

import { logIn } from '@/api/auth.service'
import { useTypedDispatch } from '@/hooks/redux'
import { setIsLoggedIn } from '@/redux/features/authSlice'

import './LoginForm.scss'

const LoginForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useTypedDispatch()

  const handleLogin = () => {
    form
      .validateFields()
      .then(() => {
        setIsLoading(true)
        logIn(form.getFieldsValue())
          .then((response) => {
            const { accessToken, refreshToken } = response.data
            if (accessToken) {
              localStorage.setItem('accessToken', accessToken.token)
              localStorage.setItem(
                'accessTokenExpiresOn',
                (Date.now() + accessToken.expiresIn * 1000).toString()
                // (Date.now() + 60 * 1000).toString()
              )
            }
            if (refreshToken) {
              localStorage.setItem('refreshToken', refreshToken.token)
              localStorage.setItem(
                'refreshTokenExpiresOn',
                (Date.now() + refreshToken.expiresIn * 1000).toString()
              )
            }
            setIsLoading(false)
            dispatch(setIsLoggedIn(true))
          })
          .catch((error) => {
            setIsLoading(false)
            message.error(
              error.response?.data?.message ||
                'Something went wrong, please try again later'
            )
          })
      })
      .catch((error) => {})
  }
  const [form] = Form.useForm()

  return (
    <Form className={'login-form'} layout={'vertical'} form={form}>
      <h1 className={'login-form__title'}>Log in</h1>
      <Form.Item
        name={'email'}
        label='Email'
        className={'login-form__input'}
        rules={[
          {
            required: true,
            type: 'email',
            message: 'The email should include “@” and “.”'
          }
        ]}
      >
        <Input placeholder='Enter email' type='email' />
      </Form.Item>
      <Form.Item
        name={'password'}
        label='Password'
        className={'login-form__input-with-prefix'}
        rules={[
          {
            required: true,
            message: 'This field is required'
          }
        ]}
      >
        <Input.Password
          placeholder='Enter password'
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Button
        loading={isLoading}
        type={'primary'}
        onClick={handleLogin}
        className={'login-form__button'}
        disabled={false}
      >
        Log in
      </Button>
      {/*{errorMessage && <p>{errorMessage}</p>}*/}
    </Form>
  )
}

export default LoginForm
