type TTryCatchWithMessage = <T>(
  tryCallback: () => Promise<T>,
  params?: {
    catchCallback?: VoidFunction
    successMessage?: string
    errorMessage?: string
  }
) => Promise<T | undefined>

const tryCatchWithMessage: TTryCatchWithMessage = async (
  tryCallback,
  params
) => {
  const { message } = await import('antd')
  const {
    catchCallback,
    successMessage,
    errorMessage = 'Error!'
  } = params || {}

  try {
    const result = await tryCallback()

    if (successMessage) {
      message.success(successMessage)
    }

    return result
  } catch (e: any) {
    catchCallback?.()
    message.error(e?.response?.data?.message || e.message || errorMessage)

    throw new Error(e)
  }
}

export default tryCatchWithMessage
