import { configureStore } from '@reduxjs/toolkit'

import authReducer from '../features/authSlice'
import formStateReducer from '../features/formStateSlice'
import timeLineReducer from '../features/timeLineSlice'

export const store = configureStore({
  reducer: {
    timeLine: timeLineReducer,
    formState: formStateReducer,
    auth: authReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
