enum EAppRoute {
  Thanks = '/thanks',
  Login = '/login',
  Dashboard = '/dashboard',
  AddPartner = '/add-partner',
  Partner = '/partner',
  Error = '/error'
}

export default EAppRoute
