import React from 'react'

interface ICopyIconProps {
  isGreenByDefault?: boolean
  isHovered?: boolean
}

const CopyIcon: React.FC<ICopyIconProps> = ({
  isGreenByDefault = false,
  isHovered = false
}) => {
  const defaultStrokeColor = isGreenByDefault
    ? 'var(--brand-primary)'
    : '#A4A4A4'

  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        transition: 'stroke 0.3s ease',
        stroke: isHovered ? 'var(--brand-primary-hover)' : defaultStrokeColor
      }}
    >
      <g clipPath='url(#clip0_2264_1395)'>
        <path
          d='M6.00024 15H5.40024C4.07024 15 3.00024 13.93 3.00024 12.6V5.4C3.00024 4.07 4.07024 3 5.40024 3H12.6002C13.9302 3 15.0002 4.07 15.0002 5.4V6M11.4002 9H18.6002C19.2368 9 19.8472 9.25286 20.2973 9.70294C20.7474 10.153 21.0002 10.7635 21.0002 11.4V18.6C21.0002 19.2365 20.7474 19.847 20.2973 20.2971C19.8472 20.7471 19.2368 21 18.6002 21H11.4002C10.7637 21 10.1533 20.7471 9.70319 20.2971C9.2531 19.847 9.00024 19.2365 9.00024 18.6V11.4C9.00024 11.0848 9.06232 10.7727 9.18293 10.4816C9.30354 10.1904 9.48033 9.9258 9.70319 9.70294C9.92605 9.48008 10.1906 9.3033 10.4818 9.18269C10.773 9.06208 11.0851 9 11.4002 9Z'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2264_1395'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.000244141)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CopyIcon
