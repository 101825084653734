import React, { useEffect, useState } from 'react'

import { Button, Form, Input, Select, message } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { useNavigate } from 'react-router-dom'

import { uploadSingleFile } from '@/api/file.service'
import { addPartner, getBranches } from '@/api/partners.service'
import FormUploadInput from '@/components/form/form-upload-input/FormUploadInput'
import EAppRoute from '@/types/appRoutes'
import { Branch } from '@/types/partners'

import './AddPartnerForm.scss'

const AddPartnerForm = () => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [branchList, setBranchList] = useState<Branch[]>([])
  const navigate = useNavigate()

  const partnerName = useWatch('partner-name', form)
  const url = useWatch('url', form)
  const primaryColor = useWatch('primary-color', form)
  const branchName = useWatch('branch-name', form)
  const partnerLogoFile = useWatch('partner-logo', form)

  const isSubmittable = partnerName && branchName

  useEffect(() => {
    getBranches()
      .then((response) => {
        setBranchList(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const handleAddPartner = () => {
    form
      .validateFields()
      .then(() => {
        setIsLoading(true)
        if (partnerLogoFile) {
          uploadSingleFile(partnerLogoFile)
            .then((fileData) => {
              const partnerData = {
                name: partnerName,
                branch: branchList.find((branch) => branch.name === branchName),
                ...(url ? { url } : {}),
                ...(primaryColor ? { primaryColor } : {}),
                ...(fileData ? { logo: fileData } : {})
              }
              addPartner(partnerData)
                .then(() => {
                  message.success('Partner added successfully')
                  form.resetFields()
                  setIsLoading(false)
                })
                .catch((error) => {
                  message.error(
                    error.response?.data?.message || 'Something went wrong!'
                  )
                  setIsLoading(false)
                })
            })
            .catch((error) => {
              message.error(
                error.response?.data?.message || 'Something went wrong!'
              )
              setIsLoading(false)
            })
        } else {
          const partnerData = {
            name: partnerName,
            branch: branchList.find((branch) => branch.name === branchName),
            ...(url ? { url } : {}),
            ...(primaryColor ? { primaryColor } : {})
          }
          addPartner(partnerData)
            .then(() => {
              message.success('Partner added successfully')
              form.resetFields()
              setIsLoading(false)
            })
            .catch((error) => {
              message.error(
                error.response?.data?.message || 'Something went wrong!'
              )
              setIsLoading(false)
            })
        }
      })
      .catch((error) => {})
  }

  const handleCancel = () => {
    navigate(EAppRoute.Dashboard)
  }

  return (
    <Form className={'add-partner-form'} form={form} layout={'vertical'}>
      <h1 className={'add-partner-form__title'}>New Partner</h1>
      <Form.Item
        name={'partner-name'}
        label='Partner Name'
        className={'add-partner-form__input'}
        rules={[
          {
            required: true,
            message: 'This field is required'
          }
        ]}
      >
        <Input placeholder='Enter name' type='text' />
      </Form.Item>
      <Form.Item name={'url'} label='URL' className={'add-partner-form__input'}>
        <Input placeholder='Enter URL' type='text' />
      </Form.Item>
      <Form.Item
        name={'primary-color'}
        label='Primary Color'
        className={'add-partner-form__input'}
      >
        <Input placeholder='Enter hex color' type='text' />
      </Form.Item>
      <Form.Item
        name={'branch-name'}
        label='Branch'
        className={'add-partner-form__select'}
        rules={[
          {
            required: true,
            message: 'Please select a Branch'
          }
        ]}
      >
        <Select placeholder='Select Branch' showSearch>
          {branchList.map((branch) => (
            <Select.Option key={branch.id} value={branch.name}>
              {branch.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <div className={'add-partner-form__file'}>
        <h3 className={'add-partner-form__form-label'}>
          Upload logo <span>(png, svg, jpeg)</span>
        </h3>
        <FormUploadInput
          name={'partner-logo'}
          fileList={partnerLogoFile}
          maxCount={1}
          required={false}
        />
      </div>
      <div className={'add-partner-form__buttons'}>
        <Button
          loading={isLoading}
          type={'primary'}
          onClick={handleAddPartner}
          className={'add-partner-form__button primary'}
          disabled={!isSubmittable}
        >
          Add Partner
        </Button>
        <Button
          type={'default'}
          onClick={handleCancel}
          className={'add-partner-form__button secondary'}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </Form>
  )
}

export default AddPartnerForm
