import { ReactElement } from 'react'

import { ConfigProvider } from 'antd'
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'

import useGetDefaultColorsMap from '@/hooks/useGetDefaultColorsMap'
import AddPartnerPage from '@/pages/add-partner/AddPartnerPage'
import DashboardPage from '@/pages/dashboard-page/DashboardPage'
import LoginPage from '@/pages/login-page/LoginPage'
import PartnerPage from '@/pages/partner/PartnerPage'
import AuthProtected from '@/router/AuthProtected'
import EAppRoute from '@/types/appRoutes'

const AppRouter = (): ReactElement => {
  const colorsMap = useGetDefaultColorsMap()

  const {
    'brand-primary': brandPrimary,
    'brand-primary-hover': brandPrimaryHover,
    'main-text': mainText
  } = colorsMap

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: brandPrimary,
          colorPrimaryHover: brandPrimaryHover,
          colorPrimaryActive: brandPrimaryHover,
          colorPrimaryBgHover: brandPrimaryHover
        },
        components: {
          Typography: {
            colorTextHeading: mainText
          },
          Button: {
            colorPrimary: brandPrimary,
            defaultBorderColor: brandPrimary
          }
        }
      }}
    >
      <HashRouter>
        <Routes>
          <Route path='/' element={<Navigate to={EAppRoute.Login} />} />

          <Route path={EAppRoute.Login} element={<LoginPage />} />
          <Route
            path={EAppRoute.Dashboard}
            element={
              <AuthProtected>
                <DashboardPage />
              </AuthProtected>
            }
          />
          <Route
            path={EAppRoute.AddPartner}
            element={
              <AuthProtected>
                <AddPartnerPage />
              </AuthProtected>
            }
          />
          <Route
            path={`${EAppRoute.Partner}/:partnerId`}
            element={
              <AuthProtected>
                <PartnerPage />
              </AuthProtected>
            }
          />

          <Route path='*' element={<Navigate to={EAppRoute.Login} />} />
        </Routes>
      </HashRouter>
    </ConfigProvider>
  )
}

export default AppRouter
