import React from 'react'

import { Button, Dropdown, MenuProps } from 'antd'
import { Link } from 'react-router-dom'

import { useTypedDispatch } from '@/hooks/redux'
import downArrowIcon from '@/images/icons/down-arrow.svg'
import logoutIcon from '@/images/icons/logout.svg'
import loanBudLogo from '@/images/loanbud-logo.svg'
import { setIsLoggedIn } from '@/redux/features/authSlice'
import EAppRoute from '@/types/appRoutes'

import './AdminHeader.scss'

const AdminHeader = () => {
  const dispatch = useTypedDispatch()

  const handleLogout = () => {
    localStorage.setItem('accessToken', '')
    localStorage.setItem('accessTokenExpiresOn', '')
    localStorage.setItem('refreshToken', '')
    localStorage.setItem('refreshTokenExpiresOn', '')
    dispatch(setIsLoggedIn(false))
  }

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <button
          key='logout'
          className={'admin-header__menu-item'}
          onClick={handleLogout}
        >
          <img src={logoutIcon} alt={'Logout icon'} width={20} height={20} />
          <span>Logout</span>
        </button>
      )
    }
  ]

  return (
    <header className={'admin-header-wrapper'}>
      <div className={'admin-header'}>
        <Link to={EAppRoute.Dashboard}>
          <img src={loanBudLogo} alt={'LoanBud logo'} />
        </Link>
        <div className={'admin-header__user'}>
          <div className={'admin-header__avatar'}>A</div>
          <Dropdown menu={{ items: menuItems }} trigger={['click']}>
            <Button
              icon={
                <img
                  src={downArrowIcon}
                  alt={'Down arrow icon'}
                  width={20}
                  height={20}
                />
              }
              type='text'
            ></Button>
          </Dropdown>
        </div>
      </div>
    </header>
  )
}

export default AdminHeader
