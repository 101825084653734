import { createQueryKeyStore } from '@lukemorales/query-key-factory'

import { Branch, PartnerDto } from '@/types/partners'

import APIinstance from './config'

export const partnersQueries = createQueryKeyStore({
  partner: {
    me: ['partner/me']
  }
})

export const getPartnerConfig = async (): Promise<Required<{}>> => {
  // const { data } = await APIinstance.get<Required<PartnerDto>>('partners/me')
  // return data
  return {}
}

export const getPartners = async (): Promise<Required<PartnerDto[]>> => {
  const { data } = await APIinstance.get<Required<PartnerDto[]>>('partners')
  return data
}

export const getPartnersBySearchQuery = async (
  query: string
): Promise<Required<PartnerDto[]>> => {
  const { data } = await APIinstance.get<Required<PartnerDto[]>>(
    `partners?search=${query}`
  )
  return data
}

export const getPartnerById = async (
  partnerId: string
): Promise<Required<PartnerDto>> => {
  const { data } = await APIinstance.get<Required<PartnerDto>>(
    `partners/${partnerId}`
  )
  return data
}

export const updatePartnerDataById = async (
  partnerId: string,
  partnerData: PartnerDto
): Promise<Required<PartnerDto>> => {
  const { data } = await APIinstance.put<Required<PartnerDto>>(
    `partners/${partnerId}`,
    partnerData
  )
  return data
}

export const addPartner = async (
  partnerData: PartnerDto
): Promise<Required<PartnerDto>> => {
  const { data } = await APIinstance.post<Required<PartnerDto>>(
    'partners',
    partnerData
  )
  return data
}

export const deletePartner = async (
  partnerId: string
): Promise<Required<PartnerDto>> => {
  const { data } = await APIinstance.delete<Required<PartnerDto>>(
    `partners/${partnerId}`
  )
  return data
}

export const getBranches = async (): Promise<Required<Branch[]>> => {
  const { data } = await APIinstance.get<Required<Branch[]>>('branches')
  return data
}
