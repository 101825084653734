import { createSlice } from '@reduxjs/toolkit'

interface IAuthInitialState {
  isLoggedIn: boolean
}

const initialState: IAuthInitialState = {
  isLoggedIn: !!(
    localStorage.getItem('accessToken') &&
    Date.now() - Number(localStorage.getItem('accessTokenExpiresOn')) < 0
  )
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn: (
      state,
      action: { payload: IAuthInitialState['isLoggedIn'] }
    ) => {
      state.isLoggedIn = action.payload
    }
  }
})

export const { setIsLoggedIn } = authSlice.actions
export default authSlice.reducer
