import axios from 'axios'

import { CloudFileDto } from '@/generated-types'
import { TFormFile } from '@/utils/formFileList'

import APIinstance from './config'

export const uploadFiles = async (
  files: TFormFile[]
): Promise<CloudFileDto[]> => {
  const formData = new FormData()

  files.forEach((file) => {
    file.originFileObj && formData.append('files', file.originFileObj)
  })

  const { data } = await APIinstance.post<CloudFileDto[]>(
    'cloud-files',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  )

  return data
}

export const uploadSingleFile = async (
  file: TFormFile[]
): Promise<CloudFileDto> => {
  const formData = new FormData()

  file[0].originFileObj && formData.append('file', file[0].originFileObj)

  const { data } = await APIinstance.post<CloudFileDto>(
    'cloud-files',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  )

  return data
}

export const getFile = async (fileId: string): Promise<void> => {
  return await APIinstance.get(`cloud-files/${fileId}`)
}

export const deleteFile = async (fileId: string): Promise<void> => {
  await APIinstance.delete(`cloud-files/${fileId}`)
}
