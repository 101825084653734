import { CloudFileDto } from '@/generated-types'
import { TFormFile } from '@/utils/formFileList'

import APIinstance from './config'

export const logIn = async (data: {
  email: string
  password: string
}): Promise<void> => {
  const { email, password } = data

  return await APIinstance.post('authentication', {
    email,
    password
  })
}
