import { StoreValue } from 'antd/es/form/interface'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload'

type TFormFile = UploadFile<RcFile> & { id?: string }

type TValidateFileList = (params: {
  fileList: TFormFile[]
  fileSizeLimit?: number
  filesMinCount?: number
  filesMaxCount?: number
  required?: boolean
}) => () => Promise<void>

const validateFileList: TValidateFileList =
  ({
    fileList,
    fileSizeLimit = 50,
    filesMinCount = 1,
    filesMaxCount: filesCountLimit = 10,
    required = true
  }) =>
  () => {
    const totalFilesSize = fileList?.reduce((acc, file) => {
      acc += file.size ?? 0
      return acc
    }, 0)

    if (required && (!fileList?.length || fileList?.length < filesMinCount)) {
      return Promise.reject(new Error('Please upload at least one file'))
    }

    if (fileList?.length > filesCountLimit) {
      return Promise.reject(
        new Error(`The maximum allowed number of files is ${filesCountLimit}`)
      )
    }

    if (fileList?.length && totalFilesSize / (1024 * 1024) > fileSizeLimit) {
      return Promise.reject(
        new Error(`The maximum allowed file size limit is ${fileSizeLimit}MB`)
      )
    }

    return Promise.resolve()
  }

const getFilesFromEvent = (e: UploadChangeParam): StoreValue => {
  if (Array.isArray(e)) {
    return e
  }

  return e?.fileList
}

export { validateFileList, getFilesFromEvent }
export type { TFormFile }
