import { deleteFile } from '@/api/file.service'

import tryCatchWithMessage from './tryCatchWithMessage'

const removeFile = async (fileId: string | undefined) => {
  if (!fileId) return

  tryCatchWithMessage(async () => {
    await deleteFile(fileId)
  })
}

export default removeFile
