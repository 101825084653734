import { ReactElement } from 'react'

const FolderIcon = (): ReactElement => {
  return (
    <svg
      width='30'
      height='25'
      viewBox='0 0 30 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.5 11.7V16.5C27.5 19.7 26.5 20.5 22.5 20.5H12.5C8.5 20.5 7.5 19.7 7.5 16.5V8.5C7.5 5.3 8.5 4.5 12.5 4.5H14C15.5 4.5 15.83 4.852 16.4 5.46L17.9 7.06C18.28 7.46 18.5 7.7 19.5 7.7H22.5C26.5 7.7 27.5 8.5 27.5 11.7Z'
        fill='var(--brand-primary-hover)'
        stroke='var(--brand-primary-hover)'
        strokeMiterlimit='10'
      />
      <path
        d='M22.5 11.7V16.5C22.5 19.7 21.5 20.5 17.5 20.5H7.5C3.5 20.5 2.5 19.7 2.5 16.5V8.5C2.5 5.3 3.5 4.5 7.5 4.5H9C10.5 4.5 10.83 4.852 11.4 5.46L12.9 7.06C13.28 7.46 13.5 7.7 14.5 7.7H17.5C21.5 7.7 22.5 8.5 22.5 11.7Z'
        fill='var(--brand-primary)'
        stroke='var(--brand-primary)'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default FolderIcon
