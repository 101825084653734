import { createSlice } from '@reduxjs/toolkit'

interface ITimelineInitialState {
  currentStep:
    | 'basic-information'
    | 'personal-information'
    | 'business-information'
    | 'review'
}

const initialState: ITimelineInitialState = {
  currentStep: 'basic-information'
}

const timeLineSlice = createSlice({
  name: 'timeLine',
  initialState,
  reducers: {
    setStep: (
      state,
      action: { payload: ITimelineInitialState['currentStep'] }
    ) => {
      state.currentStep = action.payload
    }
  }
})

export const { setStep } = timeLineSlice.actions
export default timeLineSlice.reducer
